import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import ForgotPassword from "./views/pages/login/ForgotPassword";
import ErrorBoundary from "./ErrorBoundary";
import "bootstrap/dist/css/bootstrap.min.css";
// Optional: If you're using Bootstrap JS components, you can include the JS as well
import "bootstrap/dist/js/bootstrap.bundle.min";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
// function requireAuth(nextState, replace) {
//   if (!loggedIn()) {
//     replace({
//       pathname: '/login'
//     })
//   }
// }

class App extends Component {
  render() {
    return (
      <ErrorBoundary
        fallback={
          <p>
            Something went wrong in the application. Please refresh or contact
            support.
          </p>
        }
      >
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/master/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/master/forgotpassword"
                name="Forgot Password Page"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/master/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/master/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/master/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/master"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </ErrorBoundary>
    );
  }
}

export default App;
