import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // Update state if there is an error during rendering
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // Log the error details (e.g., send to an analytics service)
  componentDidCatch(error, info) {
    console.error("Error caught by ErrorBoundary: ", error, info.componentStack);
    // You can also log this to a service or external API
    // logErrorToMyService(error, info.componentStack);
  }

  // Render fallback UI if there is an error
  render() {
    if (this.state.hasError) {
      // You can customize the fallback UI as per your needs
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>Please try again later or contact support if the issue persists.</p>
        </div>
      );
    }

    // Render the child components if no error
    return this.props.children;
  }
}

export default ErrorBoundary;
