import axios from 'axios';
import cogoToast from 'cogo-toast';
import React, { Component } from 'react';
// import swal from 'sweetalert';
// import $ from 'jquery';


class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    componentDidMount() {
        let search = new URLSearchParams(this.props.location.search);

        if (search) {
            let email = search.get("email");
            if (email) {
                this.setState({ email })
            }
        }
    }



    setForgotEmail = (e) => {
        this.setState({ email: e.target.value })

        axios({
            method: "POST",
            headers: {
                "content-type": "applicatio/json"
            },
            url: "/api/users/passwords/forgot",
            data: this.state.email
        }).then(response => {
            let mail = this.state.email;
            this.props.history.push('/verifyuser?email=' + mail)
        })
    }

    resetPassword = () => {
        const resetData = {
            email: this.state.email,
            new_password: this.state.new_password,
            validation_token: this.state.validation_token,
        }
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            url: '/api/users/passwords/reset',
            data: resetData
        }).then(response => {
            cogoToast.success("Password Reset Successful!");
            this.props.history.push('/master/login')
        }).catch(error => {
            // cogoToast.warn("error.message");
            cogoToast.warn(error.response.data.errors[0].message);
        });
    }

    setNewpassword = (e) => {
        this.setState({ new_password: e.target.value })
    }

    setValidationToken = (e) => {
        this.setState({ validation_token: e.target.value })
    }
    render() {
        return (
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    <div className='form'>
                        <div className="border mt-4 p-4">
                            <div >
                                <label>New Password</label>
                                <input className="form-control" type="password" placeholder="Enter New Password" onChange={this.setNewpassword} value={this.state.new_password} />
                                <br></br>
                                <label>Enter Token </label>
                                <input className="form-control" type="password" placeholder="Enter Token" onChange={this.setValidationToken} value={this.state.validation_token} />
                                <div className="modal-footer">
                                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                                    <button type="button" className="btn btn-primary" onClick={this.resetPassword}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4"></div>

            </div>

        );
    }
}

export default ForgotPassword;